import { loader } from "./popups.js"

const init = function() {

	// radios/text
	$('.js-summary-addition-radio:not(.js-summary-addition-done), .js-summary-addition-text:not(.js-summary-addition-done)').each(function() {
		var item = $(this);
		item.on('change', function() {
			reprice();
		});
		item.addClass('js-summary-addition-done');
	});

	// car hire options
	$('.js-summary-car-hire-option:not(.js-summary-car-hire-option-done)').each(function() {
		var input = $(this);
		var divToReplace = $('#booking-extras-replace');

		// change
		input.on('change', function() {
			if (divToReplace.attr('data-has-package') != 'False') {
				// hide div
				divToReplace.attr('data-has-package', 'False');
				// submit
				input.closest('form').find('.js-summary-show-extras').click();
			}
		});

		// done
		input.addClass('js-summary-car-hire-option-done');
	});

	// submit
	$('button.js-summary-show-extras:not(.js-summary-show-extras-done)').each(function() {
		var button = $(this);
		var divToReplace = $('#booking-extras-replace');
		var template = Hogan.compile($('#bookingOptionsSupplementsTemplate').html());

		var _hasPackage = function() {
			return divToReplace.attr('data-has-package') == 'True' ? true : false;
		}

		// submit
		button.off('click').on('click', function() {
			// continue or get extras
			if (_hasPackage()) return true;

			loader();
			// ajax request
			$.ajax({
				url: '/api/booking/options',
				method: 'POST',
				data: button.closest('form').serialize(),
				success: function(response) {

					// update/show content
					divToReplace.html(template.render(response)).attr('data-has-package', 'True').show();

					// reprice
					reprice();

					// hide loader
					loader(true);
				},
				error: function() {
					// hide loader
					loader(true);
				}
			});
			return false;
		});

		// done
		button.addClass('js-summary-show-extras-done');
	});

}

const reprice = function() {
	var subTotal = 0;
	var carUpgrade = $('.js-summary-car-upgrade');
	var departure = $('.js-summary-departure');

	// radios
	$('.js-summary-addition-radio:checked').each(function() {
		var input = $(this);
		subTotal += parseFloat(input.data('price'));
	});

	// text
	$('.js-summary-addition-text').each(function() {
		var input = $(this);
		var val = parseInt(input.val(), 10);
		if (val > 0) {
			subTotal += val * parseFloat(input.data('price'));
		}
	});

	// extras
	var extras = [];
	$('.js-summary-addition-extra').each(function() {
		var input = $(this);
		var qty = input.is(':checked') ? 1 : 0;
		if (qty != 0) {
			extras.push({
				Description: input.attr('data-title'),
				Quantity: 1
			});
		}
	});

	update(subTotal, carUpgrade, extras, departure);
}

const update = function(price, carUpgrade, extras, departure) {
	var totalElement = $('.js-summary-total');
	var animateBg = $('.m-booking-summary__total');

	//Update price
	var basePrice = parseFloat(totalElement.data('base-price'));
	totalElement.html((basePrice + price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));

	//Update departure date
	if (departure.length) {
		departure.html($('.js-summary-addition-radio:checked').data('summary-depart'));
	}

	//Update extras
	$('.js-booking-summary-extras-group').each(function() {
		var group = $(this);
		var list = group.find('.js-booking-summary-extras-list');

		// show/hide
		if (extras.length) {
			group.show();
			// empty
			list.empty();
			// add
			for (var i = 0; i < extras.length; i++) {
				var e = extras[i];
				list.append('<li>' + e.Quantity + 'x ' + e.Description + '</li>')
			}
		}
		else {
			group.hide();
		}
	});

	//Update car upgrade 
	if (carUpgrade.length) {
		carUpgrade.html($('.js-summary-addition-radio:checked').data('summary-car-hire'));
	}

	// Update car hire pricing
	let selectedPrice = parseFloat($(".js-summary-car-hire-option:checked").attr("data-price"));
	$(".js-summary-car-hire-option").each(function() {
		if ($(this).prop("checked") == true) { $(this).parent().children("span").text("Included") }
		else {
			let price = parseFloat($(this).attr("data-price"));
			let priceAdjustment = price - selectedPrice;
			if (priceAdjustment < 0) priceAdjustment = priceAdjustment * -1;
			let symbol;
			if (selectedPrice > price) { symbol = "-" } else { symbol = "+" };
			let currencySymbol = $(".m-booking-block__upgrades__item__price").attr("currency");
			let priceString = [
				symbol,
				currencySymbol,
				priceAdjustment
			].join('');
			$(this).parent().children("span").text(priceString)
		}
	})
	init();

	//Update animation class
	animateBg.addClass('flash');

	setTimeout(function() {
		animateBg.removeClass('flash');
	}, 1400);

}


export default init;
export { reprice, update }
